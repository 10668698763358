(function($) {
    $('.index-banner-slider').slick({
        fade: true,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        customPaging: function(slider, i) {
            return $('<button type="button" />').text((i + 1 + "").padStart(2, '0'));
        },
    });
    $('.index-product-slider').slick({
        arrows: false,
        dots: false,
        mobileFirst: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    rows: 2,
                    slidesPerRow: 2,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    swipe: false,
                    autoplay: false,
                    rows: 2,
                    slidesPerRow: 3,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    swipe: false,
                    autoplay: false,
                    rows: 2,
                    slidesPerRow: 4,
                }
            },
        ]
    });
    $('.index-news-slider').slick({
        arrows: false,
        dots: false,
        mobileFirst: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    rows: 2,
                    slidesPerRow: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    swipe: false,
                    autoplay: false,
                    rows: 2,
                    slidesPerRow: 2,
                }
            },
        ]
    });
    $('.index-client-slider').slick({
        arrows: true,
        dots: false,
        swipeToSlide: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    // centerMode: true,
                    variableWidth: true,
                }
            },
        ]
    });
})($);